import React, { useState } from 'react';
import { Container, Row, Col, Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

function LandingPage() {
    const [showModal, setShowModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            const response = await fetch('https://usscfedora.org/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include',
            });

            const data = await response.json();

            if (data.status === 'success') {
                navigate('/dashboard');
            } else {
                setErrorMessage(data.message || 'Login failed. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    const handleOpenModal = async () => {
        try {
            const response = await fetch('https://usscfedora.org/check_auth.php', {
                method: 'GET',
                credentials: 'include',
            });
            const data = await response.json();

            if (data.status === 'success') {
                navigate('/dashboard');
            } else {
                setShowModal(true);
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage('');
    };

    return (
        <div className="landing-page">
            <video autoPlay loop muted id="video-background">
                <source src="/black.mp4" type="video/mp4" />
                Your browser does not support video, please update your browser.
            </video>
            <div className="overlay">
                <Container fluid className="text-center text-white" style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Row>
                        <Col>
                            <h1 className="high-tech-font">Fedoraman Project</h1>
                            <h2 className="red-team-title">
                                <FontAwesomeIcon icon={faShieldAlt} /> Cybersecurity Red Team
                            </h2>
                            <p className="subtext">A group of ethical pentesters.</p>
                            <Button className="login-button" onClick={handleOpenModal} style={{ marginTop: '20px' }}>
                                Login
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Login Modal */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                container={document.querySelector('.landing-page')}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && (
                        <Alert variant="danger" style={{ marginBottom: '15px' }}>
                            {errorMessage}
                        </Alert>
                    )}
                    <Form>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword" style={{ marginTop: '10px' }}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Enter password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleLogin}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default LandingPage;
