import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'html-react-parser';
import './Projects.css';
import '../assets/loading.css';

function Projects() {
    const [projects, setProjects] = useState([]);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [newProject, setNewProject] = useState({
        title: '',
        tag: 'Not Started',
        operatingSystem: '',
        createdBy: { name: '', picture: '' }, // Replace with actual user data
        difficulty: 'Easy',
        ipAddress: '',
        description: ''
    });
    const [editingProject, setEditingProject] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);

    const difficultyColors = {
        Easy: 'green',
        Medium: 'orange',
        Hard: 'red',
        Insane: 'purple'
    };
    
    const statusColors = {
        'Not Started': 'gray',
        'In Progress': 'blue',
        'Finished': 'green'
    };

    useEffect(() => {
        // Fetch existing projects
        fetch('https://usscfedora.org/get_projects.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            setProjects(data.projects);
            setLoading(false); // Add this line
        })
        .catch(error => {
            console.error('Error fetching projects:', error);
            setLoading(false); // Add this line
        });
    
        // Fetch user profile
        fetch('https://usscfedora.org/get_user_profile.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            const profilePicture = data.profile_picture || '/default-profile.svg';
            setUserProfile(data);
            setNewProject(prevState => ({
                ...prevState,
                createdBy: { name: data.username, picture: profilePicture }
            }));
            setLoading(false); // Add this line
        })
        .catch(error => {
            console.error('Error fetching user profile:', error);
            setLoading(false); // Add this line
        });
    }, []);
      

    const handleCreateProject = () => {
        fetch('https://usscfedora.org/create_project.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(newProject),
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setProjects([...projects, data.project]);
                setNewProject({
                    title: '',
                    tag: 'Not Started',
                    operatingSystem: '',
                    createdBy: { name: 'User Name', picture: '/path/to/user/picture' }, // Reset to default or user-specific
                    difficulty: 'Easy',
                    ipAddress: '',
                    description: ''
                });
                setShowModal(false); // Close the modal after successful creation
            }
        })
        .catch(error => console.error('Error creating project:', error));
    };
    
    const handleEditProject = () => {
        fetch('https://usscfedora.org/edit_project.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(editingProject),
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                const updatedProjects = projects.map(project =>
                    project.id === editingProject.id ? editingProject : project
                );
                setProjects(updatedProjects);
                setEditingProject(null);
                setShowModal(false);
            }
        })
        .catch(error => console.error('Error editing project:', error));
    };

    const handleDeleteProject = () => {
        if (editingProject) {
            fetch(`https://usscfedora.org/delete_project.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ id: editingProject.id }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.status === 'success') {
                    setProjects(projects.filter(project => project.id !== editingProject.id));
                    setEditingProject(null);
                    setShowModal(false);
                } else {
                    console.error('Error deleting project:', data.message);
                }
            })
            .catch(error => console.error('Error deleting project:', error));
        }
    };

    const removeBBCode = (text) => {
        // Remove BBCode tags
        let cleanedText = text.replace(/\[.*?\]/g, '');
        
        // Remove HTML tags
        cleanedText = cleanedText.replace(/<\/?[^>]+(>|$)/g, '');
        
        return cleanedText;
    };
    
    
    // Truncate project description to 10 words
    const truncateText = (text, wordLimit) => {
        const cleanedText = removeBBCode(text); // Remove BBCode tags
        const words = cleanedText.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : cleanedText;
    };

    const parseBBCode = (text) => {
        return text
            .replace(/\[b\](.*?)\[\/b\]/g, '<strong>$1</strong>')
            .replace(/\[i\](.*?)\[\/i\]/g, '<em>$1</em>')
            .replace(/\[u\](.*?)\[\/u\]/g, '<u>$1</u>')
            .replace(/\[color=(.*?)\](.*?)\[\/color\]/g, '<span style="color:$1">$2</span>')
            .replace(/\[url=(.*?)\](.*?)\[\/url\]/g, '<a href="$1" target="_blank">$2</a>')
            .replace(/\[img\](.*?)\[\/img\]/g, '<img src="$1" alt="Image" style="max-width:100%" />')
            .replace(/\[table\](.*?)\[\/table\]/g, '<table>$1</table>')
            .replace(/\[tr\](.*?)\[\/tr\]/g, '<tr>$1</tr>')
            .replace(/\[td\](.*?)\[\/td\]/g, '<td>$1</td>')
            .replace(/```(.*?)```/gs, '<pre><code>$1</code></pre>'); // code blocks
    };
    

    return (
        <div className="projects-container container-fluid mt-5">
            {loading ? (
                <div className="loading-spinner"></div>
            ) : (
                <>
                    <h2 className="mb-4">Penetration Projects</h2>
                    <div className="mb-4 d-flex justify-content-end">
                        <Button variant="primary" onClick={() => {
                            setEditingProject(null); // Ensure this clears any existing editing state
                            setShowModal(true);
                        }}>
                            Create Project
                        </Button>
                    </div>
                    <Row className="project-columns">
                        {['Not Started', 'In Progress', 'Finished'].map((status) => (
                            <Col key={status} className="project-column">
                                <h3 style={{ color: statusColors[status] }}>{status}</h3>
                                {projects
                                    .filter(project => project.tag === status)
                                    .map((project) => (
                                    <Card key={project.id} className="mb-3 project-card">
                                        <Card.Body onClick={() => {
                                            setEditingProject(project);
                                            setShowViewModal(true);
                                        }}>
                                            <div className="d-flex justify-content-between">
                                                <Card.Title>{project.title}</Card.Title>
                                                <div className="difficulty-tag" style={{ backgroundColor: difficultyColors[project.difficulty], color: 'white', padding: '5px', borderRadius: '5px', display: 'inline-block', position: 'absolute', top: '10px', right: '10px' }}>
                                                    {project.difficulty}
                                                </div>
                                            </div>
                                            <Card.Subtitle className="mb-2 text-muted">{project.operatingSystem}</Card.Subtitle>
                                            <Card.Text className="project-description-preview">
                                                {truncateText(project.description, 10)} {/* Truncate to 10 words */}
                                            </Card.Text>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div>
                                                    <img
                                                        src={project.createdBy.picture}
                                                        alt={project.createdBy.name}
                                                        className="project-creator-img"
                                                    />
                                                    <span className="ml-2">{project.createdBy.name}</span>
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faEdit}
                                                    className="edit-icon"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent the card's onClick from firing
                                                        setEditingProject(project);
                                                        setShowModal(true);
                                                    }}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    ))}
                            </Col>
                        ))}
                    </Row>
                </>
            )}
    
            {/* Create/Edit Project Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="project-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{editingProject ? 'Edit Project' : 'Create Project'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingProject ? editingProject.title : newProject.title}
                                onChange={(e) => {
                                    const title = e.target.value;
                                    if (editingProject) {
                                        setEditingProject({ ...editingProject, title });
                                    } else {
                                        setNewProject({ ...newProject, title });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tag</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingProject ? editingProject.tag : newProject.tag}
                                onChange={(e) => {
                                    const tag = e.target.value;
                                    if (editingProject) {
                                        setEditingProject({ ...editingProject, tag });
                                    } else {
                                        setNewProject({ ...newProject, tag });
                                    }
                                }}
                            >
                                <option>Not Started</option>
                                <option>In Progress</option>
                                <option>Finished</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Operating System</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingProject ? editingProject.operatingSystem : newProject.operatingSystem}
                                onChange={(e) => {
                                    const operatingSystem = e.target.value;
                                    if (editingProject) {
                                        setEditingProject({ ...editingProject, operatingSystem });
                                    } else {
                                        setNewProject({ ...newProject, operatingSystem });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Difficulty</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingProject ? editingProject.difficulty : newProject.difficulty}
                                onChange={(e) => {
                                    const difficulty = e.target.value;
                                    if (editingProject) {
                                        setEditingProject({ ...editingProject, difficulty });
                                    } else {
                                        setNewProject({ ...newProject, difficulty });
                                    }
                                }}
                            >
                                <option>Easy</option>
                                <option>Medium</option>
                                <option>Hard</option>
                                <option>Insane</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>IP Address</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingProject ? editingProject.ipAddress : newProject.ipAddress}
                                onChange={(e) => {
                                    const ipAddress = e.target.value;
                                    if (editingProject) {
                                        setEditingProject({ ...editingProject, ipAddress });
                                    } else {
                                        setNewProject({ ...newProject, ipAddress });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={6}  // You can adjust the rows as needed for more space
                                value={editingProject ? editingProject.description : newProject.description}
                                onChange={(e) => {
                                    const description = e.target.value;
                                    if (editingProject) {
                                        setEditingProject({ ...editingProject, description });
                                    } else {
                                        setNewProject({ ...newProject, description });
                                    }
                                }}
                            />
                            <Form.Text className="text-muted">
                                Use [b]bold[/b], [i]italic[/i], [u]underline[/u], [color=red]colored text[/color], [url=http://example.com]link[/url], and [img]http://example.com/image.jpg[/img] to format the text.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {editingProject && (
                        <Button variant="danger" onClick={handleDeleteProject}>
                            Delete Project
                        </Button>
                    )}
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={editingProject ? handleEditProject : handleCreateProject}>
                        {editingProject ? 'Save Changes' : 'Create Project'}
                    </Button>
                </Modal.Footer>
            </Modal>
    
            {/* View Project Modal */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{editingProject?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Description</h5>
                    <div>{ReactHtmlParser(parseBBCode(editingProject?.description || ''))}</div>
                </Modal.Body>
            </Modal>
        </div>
    );    
}

export default Projects;
