import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserProfile from './components/UserProfile';
import ActivityFeed from './components/ActivityFeed';
import Projects from './components/Projects';
import Analytics from './components/Analytics';
import ExploitCentre from './components/ExploitCentre';
import AdminPanel from './components/AdminPanel';
import Roster from './components/Roster';
import ResourceCentre from './components/ResourceCentre';
import './Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';

function Dashboard() {
  const [activeKey, setActiveKey] = useState('profile');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState({ username: '', rank: '', profile_picture: '' });
  const navigate = useNavigate();

  useEffect(() => {
    // First check if the user is authenticated using check_auth.php
    fetch('https://usscfedora.org/check_auth.php', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((authData) => {
        if (authData.status !== 'success') {
          window.location.href = '/401.html'; // redirect
        } else {
          // If authenticated, fetch the user profile data
          fetch('https://usscfedora.org/get_user_profile.php', {
            method: 'GET',
            credentials: 'include',
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.username) {
                setIsAuthenticated(true);
                setUserData({
                  username: data.username || '',
                  rank: data.rank || '',
                  profile_picture: data.profile_picture || '/default-profile.svg',
                });
              } else {
                navigate('/'); // Redirect to landing page if user data is missing
              }
            })
            .catch((error) => {
              console.error('Error fetching user profile:', error);
              window.location.href = '/401.html'; // redirect
            });
        }
      })
      .catch((error) => {
        console.error('Error checking authentication:', error);
        window.location.href = '/401.html'; // redirect
      });
  }, [navigate]);

  const handleSignOut = () => {
    // Clear session data and redirect to login page
    fetch('https://usscfedora.org/logout.php', {
      method: 'POST',
      credentials: 'include',
    }).then((response) => {
      if (response.ok) {
        navigate('/'); // Redirect to landing page
      }
    });
  };

  if (!isAuthenticated) {
    return null; //
  }

  return (
    <Container fluid className="dashboard-container">
      <Row>
        <Col xs={2} style={{ width: '12.5%' }} className="sidebar">
          <div className="company-logo">
            <FontAwesomeIcon icon={faShieldAlt} className="logo-icon" />
            <span className="company-text">FEDORAMAN PROJECT</span>
          </div>
          <Nav variant="pills" className="flex-column" activeKey={activeKey} onSelect={(selectedKey) => setActiveKey(selectedKey)}>
            <Nav.Item>
              <Nav.Link eventKey="profile">Profile</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="activity">Activity Feed</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="projects">Projects</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="exploit-centre">Exploit Centre</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="roster">Roster</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="resource-centre">Resource Centre</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="analytics">Analytics</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="admin">Admin Panel</Nav.Link>
            </Nav.Item>
          </Nav>
          <div className="user-info">
            <Image src={userData.profile_picture} roundedCircle className="profile-picture2" alt="Profile Picture" />
            <div className="user-details">
              <strong>{userData.username}</strong>
              <small>{userData.rank}</small>
            </div>
            <Button variant="danger" className="sign-out-button" onClick={handleSignOut}>
              Sign Out
            </Button>
          </div>
        </Col>
        <Col xs={10} style={{ width: '87.5%' }} className="main-content">
          {activeKey === 'profile' && <UserProfile />}
          {activeKey === 'activity' && <ActivityFeed />}
          {activeKey === 'projects' && <Projects />}
          {activeKey === 'exploit-centre' && <ExploitCentre />}
          {activeKey === 'roster' && <Roster />}
          {activeKey === 'resource-centre' && <ResourceCentre />}
          {activeKey === 'analytics' && <Analytics />}
          {activeKey === 'admin' && <AdminPanel />}
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
