import React, { useEffect, useState } from 'react';
import styles from './UserProfile.module.css';
import '../assets/loading.css';

const availableLanguages = {
    HTML: '#e34c26',
    Java: '#b07219',
    Python: '#3572A5',
    PHP: '#4F5D95',
    JavaScript: '#f1e05a',
    TypeScript: '#2b7489',
    C: '#555555',
    'C++': '#f34b7d',
    'C#': '#178600',
    Ruby: '#701516',
    Go: '#00ADD8',
    Swift: '#ffac45',
    Kotlin: '#F18E33',
    Rust: '#dea584',
    Scala: '#c22d40',
    Dart: '#00B4AB',
    Lua: '#000080',
    Haskell: '#5e5086',
    Perl: '#0298c3',
    R: '#198CE7',
    Shell: '#89e051',
    PowerShell: '#012456',
    Groovy: '#e69f56',
    Elixir: '#6e4a7e',
    Clojure: '#db5855',
    Julia: '#a270ba',
    Erlang: '#B83998',
    'Objective-C': '#438eff',
    Assembly: '#6E4C13',
    MATLAB: '#e16737',
    Fortran: '#4d41b1',
    COBOL: '#004100',
    Lisp: '#3fb68b',
    'F#': '#b845fc',
    OCaml: '#3be133',
    Prolog: '#74283c',
    Scheme: '#1e4aec',
    VHDL: '#adb2cb',
    Verilog: '#b2b7f8',
    SQL: '#e38c00',
    Apex: '#1797c0',
};

const availableOperatingSystems = [
    'Windows',
    'macOS',
    'Linux',
    'Ubuntu',
    'Fedora',
    'Debian',
    'Arch Linux',
    'Red Hat',
    'Solaris',
    'FreeBSD',
    'Raspberry Pi OS',
];

function UserProfile() {
    const [user, setUser] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        username: '',
        profile_picture: '',
        description: '',
        language_proficiency: '',
        os_preference: ''
    });
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedOS, setSelectedOS] = useState([]);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [showOSModal, setShowOSModal] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch('https://usscfedora.org/get_user_profile.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.status === 'error') {
                setError(data.message);
            } else {
                setUser(data);
                setFormData({
                    username: data.username || '',
                    profile_picture: data.profile_picture || '',
                    description: data.description || '',
                    language_proficiency: data.language_proficiency || '',
                    os_preference: data.os_preference || ''
                });
                setSelectedLanguages((data.language_proficiency || '').split(',').filter(Boolean));
                setSelectedOS((data.os_preference || '').split(',').filter(Boolean));
            }
            setLoading(false);
        })
        .catch(error => {
            setError(error.message);
            setLoading(false);
        });
    }, []);
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const toggleLanguage = (language) => {
        setSelectedLanguages(prevState => {
            if (prevState.includes(language)) {
                return prevState.filter(l => l !== language);
            } else if (prevState.length < 10) {
                return [...prevState, language];
            }
            return prevState;
        });
    };

    const toggleOS = (os) => {
        setSelectedOS(prevState => {
            if (prevState.includes(os)) {
                return prevState.filter(o => o !== os);
            } else if (prevState.length < 5) {
                return [...prevState, os];
            }
            return prevState;
        });
    };

    const handleSaveChanges = () => {
        const updatedFormData = {
            ...formData,
            language_proficiency: selectedLanguages.join(','),
            os_preference: selectedOS.join(',')
        };
        fetch('https://usscfedora.org/edit_profile.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(updatedFormData)
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setUser(data.user);
                setEditMode(false);
            } else {
                setError(data.message);
            }
        })
        .catch(error => {
            setError(error.message);
        });
    };

    const handleCancelChanges = () => {
        setFormData({
            username: user.username || '',
            profile_picture: user.profile_picture || '',
            description: user.description || '',
            language_proficiency: user.language_proficiency || '',
            os_preference: user.os_preference || ''
        });
        setSelectedLanguages((user.language_proficiency || '').split(',').filter(Boolean));
        setSelectedOS((user.os_preference || '').split(',').filter(Boolean));
        setEditMode(false);
    };

    if (error) {
        return <p>Error: {error}</p>;
    }
    
    if (loading) {
        return <div className="loading-spinner"></div>; // Update this line
    }
    
    return (
        <div className={styles['user-profile-form']}>
            <div className={styles['form-group']}>
                <img 
                    className={styles['profile-picture']} 
                    src={formData.profile_picture || '/default-profile.svg'} 
                    alt="User Avatar" 
                />
                {editMode && (
                    <input 
                        type="text" 
                        name="profile_picture" 
                        value={formData.profile_picture} 
                        onChange={handleInputChange}
                        placeholder="Profile Picture URL"
                    />
                )}
            </div>
            <div className={styles['form-group']}>
                <label>Username:</label>
                {editMode ? (
                    <input 
                        type="text" 
                        name="username" 
                        value={formData.username} 
                        onChange={handleInputChange} 
                    />
                ) : (
                    <input type="text" value={formData.username} readOnly />
                )}
            </div>
            <div className={styles['form-group']}>
                <label>Email:</label>
                <input type="email" value={user?.email || ''} readOnly />
            </div>
            <div className={styles['form-group']}>
                <label>Rank:</label>
                <input type="text" value={user?.rank || ''} readOnly />
            </div>
            <div className={styles['form-group']}>
                <label>Description:</label>
                {editMode ? (
                    <textarea 
                        name="description" 
                        value={formData.description} 
                        onChange={handleInputChange} 
                    />
                ) : (
                    <textarea value={formData.description || 'No description provided'} readOnly />
                )}
            </div>
            <div className={styles['form-group']}>
                <label>Language Proficiency(s):</label>
                {editMode ? (
                    <>
                        <button onClick={() => setShowLanguageModal(true)}>Edit Languages</button>
                        <div className={styles['tags-container']}>
                            {selectedLanguages.map(tag => (
                                <span key={tag} className={styles['tag']} style={{ backgroundColor: availableLanguages[tag] }}>{tag}</span>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className={styles['tags-container']}>
                        {selectedLanguages.map(tag => (
                            <span key={tag} className={styles['tag']} style={{ backgroundColor: availableLanguages[tag] }}>{tag}</span>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles['form-group']}>
                <label>Operating System Preference(s):</label>
                {editMode ? (
                    <>
                        <button onClick={() => setShowOSModal(true)}>Edit OS Preferences</button>
                        <div className={styles['tags-container']}>
                            {selectedOS.map(os => (
                                <span key={os} className={styles['tag']}>{os}</span>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className={styles['tags-container']}>
                        {selectedOS.map(os => (
                            <span key={os} className={styles['tag']}>{os}</span>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles['form-group']}>
                <label>Account Created:</label>
                <input type="text" value={new Date(user?.created_at || Date.now()).toLocaleDateString()} readOnly />
            </div>
            <div className={styles['form-group']}>
                {editMode ? (
                    <>
                        <button onClick={handleSaveChanges} className={styles['save-button']}>Save Changes</button>
                        <button onClick={handleCancelChanges} className={styles['cancel-button']}>Cancel</button>
                    </>
                ) : (
                    <button onClick={() => setEditMode(true)} className={styles['edit-button']}>Edit Profile</button>
                )}
            </div>
    
            {/* Language Selection Modal */}
            {showLanguageModal && (
                <div className={styles['modal']}>
                    <div className={styles['modal-content']}>
                        <h3>Select Languages (Max 10)</h3>
                        <div className={styles['tags-container']}>
                            {Object.keys(availableLanguages).map(language => (
                                <div 
                                    key={language} 
                                    className={`${styles['tag']} ${selectedLanguages.includes(language) ? styles['selected'] : ''}`} 
                                    style={{ backgroundColor: availableLanguages[language] }} 
                                    onClick={() => toggleLanguage(language)}
                                >
                                    {language} {selectedLanguages.includes(language) && '✓'}
                                </div>
                            ))}
                        </div>
                        <button onClick={() => setShowLanguageModal(false)} className={styles['close-button']}>Done</button>
                    </div>
                </div>
            )}
    
            {/* OS Selection Modal */}
            {showOSModal && (
                <div className={styles['modal']}>
                    <div className={styles['modal-content']}>
                        <h3>Select Operating Systems (Max 5)</h3>
                        <div className={styles['tags-container']}>
                            {availableOperatingSystems.map(os => (
                                <div 
                                    key={os} 
                                    className={`${styles['tag']} ${selectedOS.includes(os) ? styles['selected'] : ''}`} 
                                    onClick={() => toggleOS(os)}
                                >
                                    {os} {selectedOS.includes(os) && '✓'}
                                </div>
                            ))}
                        </div>
                        <button onClick={() => setShowOSModal(false)} className={styles['close-button']}>Done</button>
                    </div>
                </div>
            )}
        </div>
    );    
}

export default UserProfile;
