import React, { useState, useEffect } from 'react';
import './Roster.css';
import '../assets/loading.css';
import { Container, Row, Col, Card } from 'react-bootstrap';

const availableLanguages = {
    HTML: '#e34c26',
    Java: '#b07219',
    Python: '#3572A5',
    PHP: '#4F5D95',
    JavaScript: '#f1e05a',
    TypeScript: '#2b7489',
    C: '#555555',
    'C++': '#f34b7d',
    'C#': '#178600',
    Ruby: '#701516',
    Go: '#00ADD8',
    Swift: '#ffac45',
    Kotlin: '#F18E33',
    Rust: '#dea584',
    Scala: '#c22d40',
    Dart: '#00B4AB',
    Lua: '#000080',
    Haskell: '#5e5086',
    Perl: '#0298c3',
    R: '#198CE7',
    Shell: '#89e051',
    PowerShell: '#012456',
    Groovy: '#e69f56',
    Elixir: '#6e4a7e',
    Clojure: '#db5855',
    Julia: '#a270ba',
    Erlang: '#B83998',
    'Objective-C': '#438eff',
    Assembly: '#6E4C13',
    MATLAB: '#e16737',
    Fortran: '#4d41b1',
    COBOL: '#004100',
    Lisp: '#3fb68b',
    'F#': '#b845fc',
    OCaml: '#3be133',
    Prolog: '#74283c',
    Scheme: '#1e4aec',
    VHDL: '#adb2cb',
    Verilog: '#b2b7f8',
    SQL: '#e38c00',
    Apex: '#1797c0',
};

const availableOperatingSystems = [
    'Windows',
    'macOS',
    'Linux',
    'Ubuntu',
    'Fedora',
    'Debian',
    'Arch Linux',
    'Red Hat',
    'Solaris',
    'FreeBSD',
    'Raspberry Pi',
];

function Roster() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('https://usscfedora.org/get_roster.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            setUsers(data.users);
            setLoading(false);
        })
        .catch(error => {
            console.error('Error fetching roster:', error);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div className="loading-spinner"></div>;
    }

    return (
        <Container className="roster-container mt-5">
            <h2 className="roster-title">The Team</h2>
            <div className="roster-content">  {/* Added this wrapper */}
                <Row className="g-4">
                    {users.map(user => (
                        <Col key={user.username || Math.random()} md={4} sm={6}>
                            <Card className="user-card">
                                <Card.Img 
                                    variant="top" 
                                    src={user.profile_picture || '/default-profile.svg'} 
                                    alt={`${user.username || 'Unknown'}'s avatar`} 
                                    className="profile-picture" 
                                />
                                <Card.Body>
                                    <Card.Title>{user.username || 'Unknown User'}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{user.rank || 'No Rank'}</Card.Subtitle>
                                    <Card.Text><strong>Description:</strong> {user.description || 'No description provided'}</Card.Text>
                                    
                                    {/* Language Proficiency Section */}
                                    <Card.Text><strong>Language Proficiency:</strong></Card.Text>
                                    <div className="tags-container">
                                        {(user.language_proficiency?.split(',').filter(Boolean) || []).map(language => (
                                            <span key={language} className="tag" style={{ backgroundColor: availableLanguages[language] || '#6c757d' }}>{language}</span>
                                        ))}
                                    </div>

                                    {/* Operating System Preferences Section */}
                                    <Card.Text><strong>Operating System Preference(s):</strong></Card.Text>
                                    <div className="tags-container">
                                        {(user.os_preference?.split(',').filter(Boolean) || []).map(os => (
                                            <span key={os} className="tag">{os}</span>
                                        ))}
                                    </div>

                                    <Card.Text><strong>Joined:</strong> {new Date(user.created_at || Date.now()).toLocaleDateString()}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div> {/* Close the wrapper div */}
        </Container>
    );  
}

export default Roster;
