import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import styles from './ResourceCentre.module.css';

function ResourceCentre() {
    const resources = [
        {
            title: 'Hack The Box',
            description: 'A platform to test and advance your skills in penetration testing and cybersecurity.',
            link: 'https://www.hackthebox.com/',
        },
        {
            title: 'Offensive Security',
            description: 'The creators of Kali Linux and the OSCP certification, offering advanced cybersecurity training.',
            link: 'https://www.offsec.com/',
        },
        {
            title: 'TryHackMe',
            description: 'An interactive platform for learning and practicing cybersecurity in a hands-on way.',
            link: 'https://tryhackme.com/',
        },
        {
            title: 'Cybrary',
            description: 'A free platform offering cybersecurity and IT training through online courses.',
            link: 'https://www.cybrary.it/',
        },
        {
            title: 'OWASP',
            description: 'The Open Web Application Security Project focuses on improving software security and offers numerous resources.',
            link: 'https://owasp.org/',
        },
        {
            title: 'PortSwigger Web Security Academy',
            description: 'A free resource offering in-depth training on web application security and vulnerabilities.',
            link: 'https://portswigger.net/web-security',
        },
        {
            title: 'CTFtime',
            description: 'A platform for tracking Capture The Flag (CTF) competitions, perfect for honing your hacking skills.',
            link: 'https://ctftime.org',
        }
    ];

    return (
        <Container className={styles.resourceCentreContainer}>
            <h2 className={styles.sectionTitle}>Resource Centre</h2>
            <Row>
                {resources.map((resource, index) => (
                    <Col key={index} md={6} className="mb-4">
                        <Card className={styles.resourceCard}>
                            <Card.Body>
                                <Card.Title className={styles.cardTitle}>{resource.title}</Card.Title>
                                <Card.Text className={styles.cardDescription}>
                                    {resource.description}
                                </Card.Text>
                                <a href={resource.link} target="_blank" rel="noopener noreferrer" className={styles.resourceLink}>
                                    Visit {resource.title}
                                </a>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default ResourceCentre;
