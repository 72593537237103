import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Table } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './Analytics.module.css';
import '../assets/loading.css';

function Analytics() {
  const [projectStats, setProjectStats] = useState({
    completionRate: 0,
    mostRecentProjects: [],
    projectsByDifficulty: { Easy: 0, Medium: 0, Hard: 0, Insane: 0 },
    projectsByOS: {},
    projectsByTag: { 'Not Started': 0, 'In Progress': 0, 'Finished': 0 },
  });

  const [securityMetrics, setSecurityMetrics] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Fetch and process project stats
    fetch('https://usscfedora.org/get_projects.php', {
      method: 'GET',
      credentials: 'include', // Ensure credentials are included
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch project stats: ' + response.status);
        }
        return response.json();
      })
      .then(data => {
        if (data.status === 'success') {
          const projects = data.projects;
  
          // Calculate completion rate
          const totalProjects = projects.length;
          const completedProjects = projects.filter(project => project.tag === 'Finished').length;
          const completionRate = totalProjects > 0 ? Math.round((completedProjects / totalProjects) * 100) : 0;
  
          // Get the most recent projects
          const mostRecentProjects = projects
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
            .slice(0, 5) // Limit to 5 most recent projects
            .map(project => ({
              title: project.title,
              updated_at: project.updated_at,
              details: project.details,
            }));
  
          // Projects by difficulty
          const projectsByDifficulty = { Easy: 0, Medium: 0, Hard: 0, Insane: 0 };
          projects.forEach(project => {
            if (projectsByDifficulty[project.difficulty] !== undefined) {
              projectsByDifficulty[project.difficulty]++;
            }
          });
  
          // Projects by OS
          const projectsByOS = {};
          projects.forEach(project => {
            if (projectsByOS[project.operatingSystem]) {
              projectsByOS[project.operatingSystem]++;
            } else {
              projectsByOS[project.operatingSystem] = 1;
            }
          });
  
          // Projects by tag
          const projectsByTag = { 'Not Started': 0, 'In Progress': 0, 'Finished': 0 };
          projects.forEach(project => {
            if (projectsByTag[project.tag] !== undefined) {
              projectsByTag[project.tag]++;
            }
          });
  
          setProjectStats({
            completionRate: completionRate,
            mostRecentProjects: mostRecentProjects,
            projectsByDifficulty: projectsByDifficulty,
            projectsByOS: projectsByOS,
            projectsByTag: projectsByTag,
          });
        } else {
          throw new Error('Failed to fetch project stats');
        }
      })
      .catch(error => console.error('Error fetching project stats:', error))
      .finally(() => setLoading(false)); // Set loading to false when done
  
    // Fetch and set security metrics (failed login attempts)
    fetch('https://usscfedora.org/get_failed_logins.php', {
      method: 'GET',
      credentials: 'include', // Ensure credentials are included
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch security metrics: ' + response.status);
        }
        return response.json();
      })
      .then(data => setSecurityMetrics(data.logs))
      .catch(error => console.error('Error fetching security metrics:', error))
      .finally(() => setLoading(false)); // Set loading to false when done
  }, []);

  const difficultyData = {
    labels: ['Easy', 'Medium', 'Hard', 'Insane'],
    datasets: [
      {
        label: 'Projects by Difficulty',
        backgroundColor: ['#28a745', '#ffc107', '#dc3545', '#6f42c1'], // Colors for Easy, Medium, Hard, Insane
        data: [
          projectStats.projectsByDifficulty.Easy,
          projectStats.projectsByDifficulty.Medium,
          projectStats.projectsByDifficulty.Hard,
          projectStats.projectsByDifficulty.Insane,
        ],
      },
    ],
  };

  const difficultyOptions = {
    scales: {
      x: {
        grid: {
          color: '#404040', // Set gridline color to a lighter grey
        },
      },
      y: {
        grid: {
          color: '#404040', // Set gridline color to a lighter grey
        },
      },
    },
  };

  return (
    <Container fluid className={styles.analyticsContainer}>
      {loading ? (
        <div className="loading-spinner"></div> // Display the spinner while loading
      ) : (
        <>
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>Project Analytics</h2>
              <Card className={styles.analyticsCard}>
                <Card.Body className={styles.cardBody}>
                  <Card.Title className={styles.cardTitle}>Project Completion Rate: {projectStats.completionRate}%</Card.Title>
                  <Card.Text className={styles.cardText}>
                        Most Recent Projects:
                  </Card.Text>
                  <ul className={styles.list}>
                    {projectStats.mostRecentProjects.map((project, index) => (
                      <li key={index} className={styles.listItem}>
                        <strong>{project.title}</strong> (Last updated: {new Date(project.updated_at).toLocaleDateString()})
                        <p>{project.details}</p>
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <h2 className={styles.sectionTitle}>Projects by Difficulty</h2>
              <Card className={styles.analyticsCard}>
                <Card.Body className={styles.cardBody}>
                  <Bar data={difficultyData} options={difficultyOptions} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>Projects by Operating System</h2>
              <Card className={styles.analyticsCard}>
                <Card.Body className={styles.cardBody}>
                  <ul className={styles.list}>
                    {Object.keys(projectStats.projectsByOS).map((os, index) => (
                      <li key={index} className={styles.listItem}>
                        {os}: {projectStats.projectsByOS[os]}
                      </li>
                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <h2 className={styles.sectionTitle}>Projects by Tag</h2>
              <Card className={styles.analyticsCard}>
                <Card.Body className={styles.cardBody}>
                  <Card.Text className={`${styles.cardText} ${styles.tagItem}`}>
                    <span className={styles.tagBadge} style={{backgroundColor: '#ffc107'}}>Not Started</span>: {projectStats.projectsByTag['Not Started']}
                  </Card.Text>
                  <Card.Text className={`${styles.cardText} ${styles.tagItem}`}>
                    <span className={styles.tagBadge} style={{backgroundColor: '#007bff'}}>In Progress</span>: {projectStats.projectsByTag['In Progress']}
                  </Card.Text>
                  <Card.Text className={`${styles.cardText} ${styles.tagItem}`}>
                    <span className={styles.tagBadge} style={{backgroundColor: '#28a745'}}>Finished</span>: {projectStats.projectsByTag['Finished']}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>Security Metrics</h2>
              <Card className={styles.analyticsCard}>
                <Card.Body className={styles.cardBody}>
                  <Card.Title className={styles.cardTitle}>Failed Login Attempts</Card.Title>
                  <div className={styles.tableContainer}> {/* Add this div around the Table */}
                    <Table striped bordered hover variant="dark" className={styles.table}>
                      <thead>
                        <tr>
                          <th>Username</th>
                          <th>IP Address</th>
                          <th>Attempted At</th>
                          <th>User Agent</th>
                        </tr>
                      </thead>
                      <tbody>
                        {securityMetrics.map((log, index) => (
                          <tr key={index}>
                            <td>{log.username}</td>
                            <td>{log.ip_address}</td>
                            <td>{new Date(log.attempted_at).toLocaleString()}</td>
                            <td>{log.user_agent}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default Analytics;
