import React, { useState, useEffect } from 'react';
import { Button, Form, Table, Modal, Alert } from 'react-bootstrap';
import './ExploitCentre.css';
import '../assets/loading.css';

function ExploitCentre() {
    const [exploits, setExploits] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedExploit, setSelectedExploit] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const [newExploit, setNewExploit] = useState({
        title: '',
        vulnerability: '',
        affectedSystems: '',
        pocCode: '',
        status: 'In Development',
        tags: '',
        file: null
    });

    useEffect(() => {
        fetchExploits();
    }, []);

    const fetchExploits = () => {
        setLoading(true);
        fetch('https://usscfedora.org/get_exploits.php')
            .then(response => response.json())
            .then(data => {
                setExploits(data.exploits);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching exploits:', error);
                setLoading(false);
            });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Check if file already exists
            fetch(`https://usscfedora.org/check_file_exists.php?filename=${file.name}`)
                .then(response => response.json())
                .then(data => {
                    if (data.exists) {
                        setErrorMessage('A file with this name already exists. Please rename your file.');
                    } else {
                        setNewExploit({ ...newExploit, file: file });
                        setErrorMessage('');
                    }
                })
                .catch(error => console.error('Error checking file existence:', error));
        }
    };

    const handleSubmit = () => {
        if (errorMessage) {
            alert('Please resolve the file name conflict before submitting.');
            return;
        }

        const formData = new FormData();
        for (const key in newExploit) {
            formData.append(key, newExploit[key]);
        }

        fetch('https://usscfedora.org/upload_exploit.php', {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                fetchExploits();
                setShowModal(false);
                setNewExploit({
                    title: '',
                    vulnerability: '',
                    affectedSystems: '',
                    pocCode: '',
                    status: 'In Development',
                    tags: '',
                    file: null
                });
            }
        })
        .catch(error => console.error('Error uploading exploit:', error));
    };

    const handleEditSubmit = () => {
        fetch('https://usscfedora.org/edit_exploit.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(selectedExploit),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.status === 'success') {
                fetchExploits();
                setShowEditModal(false);
            } else {
                console.error('Error editing exploit:', data.message);
            }
        })
        .catch(error => console.error('Error editing exploit:', error));
    };

    const handleDelete = (id) => {
        fetch('https://usscfedora.org/delete_exploit.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ id }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.status === 'success') {
                fetchExploits();
            } else {
                console.error('Error deleting exploit:', data.message);
            }
        })
        .catch(error => console.error('Error deleting exploit:', error));
    };

    const filteredExploits = exploits.filter(exploit =>
        exploit.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exploit.vulnerability.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exploit.affectedSystems.toLowerCase().includes(searchTerm.toLowerCase()) ||
        exploit.tags.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="exploit-centre">
            <h2>Internal Exploit Database</h2>
            <p className="text-center">Click on an exploit for more information</p>
            <Button variant="primary" onClick={() => setShowModal(true)}>Add New Exploit</Button>

            <Form.Group className="mb-3">
                <Form.Control 
                    type="text" 
                    placeholder="Search exploits..." 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </Form.Group>

            {loading ? (
                <div className="loading-spinner"></div>
            ) : (

            <Table striped bordered hover className="mt-4">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Vulnerability</th>
                        <th>Affected Systems</th>
                        <th>Status</th>
                        <th>Tags</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredExploits.map((exploit, index) => (
                        <tr key={index} onClick={() => { setSelectedExploit(exploit); setShowViewModal(true); }}>
                            <td>{exploit.title}</td>
                            <td>{exploit.vulnerability}</td>
                            <td>{exploit.affectedSystems}</td>
                            <td>{exploit.status}</td>
                            <td>{exploit.tags}</td>
                            <td>
                                {exploit.filePath && exploit.filePath.trim() !== '' && exploit.filePath !== 'uploads/' && (
                                    <Button variant="info" onClick={(e) => { e.stopPropagation(); window.open(`https://usscfedora.org/download_exploit.php?id=${exploit.id}`, '_blank'); }}>
                                        Download
                                    </Button>
                                )}
                                {' '}
                                <Button variant="warning" onClick={(e) => { e.stopPropagation(); setSelectedExploit(exploit); setShowEditModal(true); }}>
                                    Edit
                                </Button>{' '}
                                <Button variant="danger" onClick={(e) => { e.stopPropagation(); handleDelete(exploit.id); }}>
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            )}

            {/* Add New Exploit Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Exploit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newExploit.title} 
                                onChange={(e) => setNewExploit({ ...newExploit, title: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vulnerability</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newExploit.vulnerability} 
                                onChange={(e) => setNewExploit({ ...newExploit, vulnerability: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Affected Systems</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newExploit.affectedSystems} 
                                onChange={(e) => setNewExploit({ ...newExploit, affectedSystems: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Proof of Concept Code</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                value={newExploit.pocCode} 
                                onChange={(e) => setNewExploit({ ...newExploit, pocCode: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={newExploit.status} 
                                onChange={(e) => setNewExploit({ ...newExploit, status: e.target.value })} 
                            >
                                <option>In Development</option>
                                <option>Tested</option>
                                <option>Deployed</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tags</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newExploit.tags} 
                                onChange={(e) => setNewExploit({ ...newExploit, tags: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Upload File</Form.Label>
                            <Form.Control 
                                type="file" 
                                onChange={handleFileChange} 
                            />
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* View Exploit Details Modal */}
            <Modal show={showViewModal} onHide={() => setShowViewModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedExploit?.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Vulnerability</h5>
                    <p>{selectedExploit?.vulnerability}</p>
                    <h5>Affected Systems</h5>
                    <p>{selectedExploit?.affectedSystems}</p>
                    <h5>Proof of Concept Code</h5>
                    <pre>{selectedExploit?.pocCode}</pre>
                    <h5>Status</h5>
                    <p>{selectedExploit?.status}</p>
                    <h5>Tags</h5>
                    <p>{selectedExploit?.tags}</p>
                </Modal.Body>
            </Modal>

            {/* Edit Exploit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Exploit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Title</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={selectedExploit?.title} 
                                onChange={(e) => setSelectedExploit({ ...selectedExploit, title: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Vulnerability</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={selectedExploit?.vulnerability} 
                                onChange={(e) => setSelectedExploit({ ...selectedExploit, vulnerability: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Affected Systems</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={selectedExploit?.affectedSystems} 
                                onChange={(e) => setSelectedExploit({ ...selectedExploit, affectedSystems: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Proof of Concept Code</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                value={selectedExploit?.pocCode} 
                                onChange={(e) => setSelectedExploit({ ...selectedExploit, pocCode: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Status</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={selectedExploit?.status} 
                                onChange={(e) => setSelectedExploit({ ...selectedExploit, status: e.target.value })} 
                            >
                                <option>In Development</option>
                                <option>Tested</option>
                                <option>Deployed</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tags</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={selectedExploit?.tags} 
                                onChange={(e) => setSelectedExploit({ ...selectedExploit, tags: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Upload File</Form.Label>
                            <Form.Control 
                                type="file" 
                                onChange={handleFileChange} 
                            />
                            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleEditSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ExploitCentre;