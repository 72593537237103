import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import styles from './ActivityFeed.module.css';
import '../assets/loading.css';

function ActivityFeed() {
  const [projectActivities, setProjectActivities] = useState([]);
  const [achievementProgress, setAchievementProgress] = useState(0);
  const [systemNotifications, setSystemNotifications] = useState([]);
  const [exploitActivities, setExploitActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch Project Activities and calculate achievement progress
    fetch('https://usscfedora.org/get_projects.php', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch project activities: ' + response.status);
        }
        return response.text();
      })
      .then((text) => {
        const data = JSON.parse(text);
        if (data.status === 'success') {
          const projects = data.projects;

          // Track project activities
          const activities = projects.map((project) => ({
            title: project.title,
            updated_at: project.updated_at,
            action: project.created_at === project.updated_at ? 'created' : 'updated',
            username: project.createdBy.name,
          }));
          setProjectActivities(activities);

          // Calculate achievement progress (e.g., completion of 3 projects)
          const completedProjects = projects.filter((project) => project.tag === 'Finished').length;
          const progress = Math.min(Math.round((completedProjects / 3) * 100), 100);
          setAchievementProgress(progress);
        } else {
          throw new Error('Failed to fetch project activities');
        }
      })
      .catch((error) => console.error('Error fetching project activities:', error))
      .finally(() => setLoading(false)); // Set loading to false when done
      
    // Fetch system notifications
    fetch('https://usscfedora.org/get_system_notifications.php', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch system notifications: ' + response.status);
        }
        return response.json();
      })
      .then((data) => setSystemNotifications(data.notifications))
      .catch((error) => console.error('Error fetching system notifications:', error))
      .finally(() => setLoading(false)); // Set loading to false when done

    // Fetch Exploit Activities
    fetch('https://usscfedora.org/get_exploits.php', {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch exploit activities: ' + response.status);
        }
        return response.json();
      })
      .then((data) => setExploitActivities(data.exploits))
      .catch((error) => console.error('Error fetching exploit activities:', error))
      .finally(() => setLoading(false)); // Set loading to false when done

  }, []);

  return (
    <Container fluid className={styles.activityFeedContainer}>
      {loading ? (
        <div className="loading-spinner"></div> // Display the spinner while loading
      ) : (
        <>
          {/* System Notifications - 1st place */}
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>System Notifications</h2>
              <Card className={styles.notificationCard}>
                <Card.Body className={styles.cardBody}>
                  {systemNotifications.length > 0 ? (
                    <>
                      {systemNotifications.slice(0, 5).map((notification, index) => (
                        <div key={index} className={`${styles.notificationItem} ${styles.notificationText}`}>
                          <strong>{notification.type}</strong> {notification.message}
                          <div className={styles.notificationTimestamp}>
                            {new Date(notification.timestamp).toLocaleString()}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <p>No new system notifications.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Achievements - 2nd Place */}
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>Achievements</h2>
              <Card className={styles.achievementCard}>
                <Card.Body className={styles.cardBody}>
                  <h4>Complete 3 Projects</h4>
                  <ProgressBar now={achievementProgress} label={`${achievementProgress}%`} />
                  <p>Progress: {achievementProgress}%</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Project Activities - 3rd place */}
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>Project Activities</h2>
              <Card className={styles.activityCard}>
                <Card.Body className={styles.cardBody}>
                  {projectActivities.length > 0 ? (
                    <ul className={styles.list}>
                      {projectActivities.map((activity, index) => (
                        <li key={index} className={styles.listItem}>
                          <strong>{activity.username}</strong> {activity.action} <strong>{activity.title}</strong> on {new Date(activity.updated_at).toLocaleString()}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No recent project activities.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Exploit Activities - 4th place */}
          <Row>
            <Col>
              <h2 className={styles.sectionTitle}>Exploit Activities</h2>
              <Card className={styles.activityCard}>
                <Card.Body className={styles.cardBody}>
                  {exploitActivities.length > 0 ? (
                    <ul className={styles.list}>
                      {exploitActivities.map((exploit, index) => (
                        <li key={index} className={styles.listItem}>
                          <strong>{exploit.title}</strong>: {exploit.vulnerability} affecting {exploit.affectedSystems}. Status: {exploit.status}
                          <p>Tags: {exploit.tags}</p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No recent exploit activities.</p>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}

export default ActivityFeed;
