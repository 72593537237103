import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Alert, Spinner, Row, Col } from 'react-bootstrap';
import './AdminPanel.css';
import '../assets/loading.css';
import { useNavigate } from 'react-router-dom';

function AdminPanel() {
    const [loading, setLoading] = useState(true);
    const [authorized, setAuthorized] = useState(false);
    const [message, setMessage] = useState(null);
    const [newUser, setNewUser] = useState({
        username: '',
        password: '',
        email: '',
        rank: 'guest',
    });
    const [users, setUsers] = useState([]); // State to hold the list of users
    const [deleteUsername, setDeleteUsername] = useState('');
    const [systemMessage, setSystemMessage] = useState('');
    const [currentSystemMessage, setCurrentSystemMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://usscfedora.org/check_admin.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            if (data.status === 'success') {
                setAuthorized(true);
                fetchUsers(); // Fetch the users list once authorized
                fetchSystemMessage(); // Fetch the current system message
            } else {
                window.location.href = '/401.html'; // Redirect to 401.html
            }
            setLoading(false);
        })
        .catch(error => {
            console.error('Error verifying admin:', error);
            setLoading(false);
            navigate('/401');
        });
    }, [navigate]);

    const fetchUsers = () => {
        fetch('https://usscfedora.org/get_users.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            setUsers(data.users || []); // Update the users state with the fetched users
        })
        .catch(error => {
            console.error('Error fetching users:', error);
        });
    };

    const fetchSystemMessage = () => {
        fetch('https://usscfedora.org/get_system_notifications.php', {
            method: 'GET',
            credentials: 'include',
        })
        .then(response => response.json())
        .then(data => {
            if (data.notifications && data.notifications.length > 0) {
                setCurrentSystemMessage(data.notifications[0].message);
            }
        })
        .catch(error => {
            console.error('Error fetching system notifications:', error);
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser(prevState => ({ ...prevState, [name]: value }));
    };

    const handleDeleteInputChange = (e) => {
        setDeleteUsername(e.target.value);
    };

    const handleSystemMessageChange = (e) => {
        setSystemMessage(e.target.value);
    };

    const handleCreateUser = () => {
        setLoading(true);
        fetch('https://usscfedora.org/create_user.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(newUser),
        })
        .then(response => response.json())
        .then(data => {
            setMessage(data.message);
            setLoading(false);
            if (data.status === 'success') {
                setNewUser({
                    username: '',
                    password: '',
                    email: '',
                    rank: 'guest',
                });
                fetchUsers(); // Refresh the users list after creating a new user
            }
        })
        .catch(error => {
            console.error('Error creating user:', error);
            setLoading(false);
        });
    };

    const handleDeleteUser = () => {
        setLoading(true);
        fetch('https://usscfedora.org/delete_user.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ username: deleteUsername }),
        })
        .then(response => response.json())
        .then(data => {
            setMessage(data.message);
            setLoading(false);
            if (data.status === 'success') {
                setDeleteUsername('');
                fetchUsers(); // Refresh the users list after deleting a user
            }
        })
        .catch(error => {
            console.error('Error deleting user:', error);
            setLoading(false);
        });
    };

    const handleUpdateSystemMessage = () => {
        setLoading(true);
        fetch('https://usscfedora.org/update_system_message.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ message: systemMessage }),
        })
        .then(response => response.json())
        .then(data => {
            setMessage(data.message);
            setLoading(false);
            if (data.status === 'success') {
                setCurrentSystemMessage(systemMessage); // Update current message on success
                setSystemMessage(''); // Clear input
            }
        })
        .catch(error => {
            console.error('Error updating system message:', error);
            setLoading(false);
        });
    };

    if (loading) {
        return <div className="loading-spinner"></div>;
    }

    if (!authorized) {
        return null; // Prevents rendering if unauthorized
    }

    return (
        <Container className="admin-panel-container mt-5">
            <h2 className="text-center mb-5">Admin Panel</h2>
            {message && <Alert variant="info">{message}</Alert>}
            <Row>
                <Col md={6}>
                    <h3>Create User</h3>
                    <Form>
                        <Form.Group controlId="formUsername">
                            <Form.Label>Username</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="username" 
                                value={newUser.username} 
                                onChange={handleInputChange} 
                                placeholder="Enter username" 
                            />
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                name="password" 
                                value={newUser.password} 
                                onChange={handleInputChange} 
                                placeholder="Enter password" 
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control 
                                type="email" 
                                name="email" 
                                value={newUser.email} 
                                onChange={handleInputChange} 
                                placeholder="Enter email" 
                            />
                        </Form.Group>

                        <Form.Group controlId="formRank">
                            <Form.Label>Rank</Form.Label>
                            <Form.Control 
                                as="select" 
                                name="rank" 
                                value={newUser.rank} 
                                onChange={handleInputChange}
                            >
                                <option value="guest">Guest</option>
                                <option value="member">Member</option>
                                <option value="root">Root</option>
                            </Form.Control>
                        </Form.Group>

                        <Button variant="primary" onClick={handleCreateUser} className="mt-3">
                            {loading ? <Spinner animation="border" size="sm" /> : 'Create User'}
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <h3>Delete User</h3>
                    <Form>
                        <Form.Group controlId="formDeleteUsername">
                            <Form.Label>Select User to Delete</Form.Label>
                            <Form.Control 
                                as="select" 
                                value={deleteUsername} 
                                onChange={handleDeleteInputChange}
                            >
                                <option value="">Select a user</option>
                                {users.map((user) => (
                                    <option key={user.username} value={user.username}>
                                        {user.username}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Button variant="danger" onClick={handleDeleteUser} className="mt-3">
                            {loading ? <Spinner animation="border" size="sm" /> : 'Delete User'}
                        </Button>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col md={12} className="mt-5">
                    <h3>System Notifications</h3>
                    <Form>
                        <Form.Group controlId="formSystemMessage">
                            <Form.Label>Current System Message</Form.Label>
                            <p>{currentSystemMessage || 'No current system message.'}</p>
                            <Form.Label>New System Message</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={systemMessage} 
                                onChange={handleSystemMessageChange} 
                                placeholder="Enter new system message" 
                            />
                        </Form.Group>

                        <Button variant="primary" onClick={handleUpdateSystemMessage} className="mt-3">
                            {loading ? <Spinner animation="border" size="sm" /> : 'Update System Message'}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default AdminPanel;
